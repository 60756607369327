@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');


*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  
}

body {
  background-color: #e4e1dd;
  height: 100vh;
}

#root {
  margin: 0 auto;   
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #E4E1DD;
}



 